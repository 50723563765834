import request from '@/utils/request'

// api地址
const api = {
  category_list: 'psi/category_list',
  category_save: 'psi/category_save',
  category_del: 'psi/category_del',
  goods_list: 'psi/goods_list',
  goods_save: 'psi/goods_save',
  goods_del: 'psi/goods_del',
  customer_list: 'psi/customer_list',
  customer_save: 'psi/customer_save',
  customer_del: 'psi/customer_del',
  order_list: 'psi/order_list',
  order_save: 'psi/order_save',
  order_del: 'psi/order_del',
  order_import: 'psi/order_import',
  inventory_list: 'psi/inventory_list',
  inventorydetail_list: 'psi/inventorydetail_list',
  goodstree_list: 'psi/goodstree_list',
}

// 列表
export const category_list = (param) => {
  return request({
    url: api.category_list,
    method: 'get',
  	params: param,
  })
}

// 
export const category_save = (data) => {
  return request({
    url: api.category_save,
    method: 'post',
  	data
  })
}

export const category_del = (data) => {
  return request({
    url: api.category_del,
    method: 'post',
  	data
  })
}

export const goods_list = (param) => {
  return request({
    url: api.goods_list,
    method: 'get',
  	params: param,
  })
}

// 
export const goods_save = (data) => {
  return request({
    url: api.goods_save,
    method: 'post',
  	data
  })
}

export const goods_del = (data) => {
  return request({
    url: api.goods_del,
    method: 'post',
  	data
  })
}

export const customer_list = (param) => {
  return request({
    url: api.customer_list,
    method: 'get',
  	params: param,
  })
}

// 
export const customer_save = (data) => {
  return request({
    url: api.customer_save,
    method: 'post',
  	data
  })
}

export const customer_del = (data) => {
  return request({
    url: api.customer_del,
    method: 'post',
  	data
  })
}

export const order_list = (param) => {
  return request({
    url: api.order_list,
    method: 'get',
  	params: param,
  })
}

// 
export const order_save = (data) => {
  return request({
    url: api.order_save,
    method: 'post',
  	data
  })
}

export const order_del = (data) => {
  return request({
    url: api.order_del,
    method: 'post',
  	data
  })
}

export const order_import = (data) => {
  return request({
    url: api.order_import,
    method: 'post',
  	data
  })
}

export const inventory_list = (param) => {
  return request({
    url: api.inventory_list,
    method: 'get',
  	params: param,
  })
}

export const inventorydetail_list = (param) => {
  return request({
    url: api.inventorydetail_list,
    method: 'get',
  	params: param,
  })
}

export const goodstree_list = (param) => {
  return request({
    url: api.goodstree_list,
    method: 'get',
  	params: param,
  })
}