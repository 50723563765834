<template>
	<div class="my-import">
		<div class="my-title clearfix">
			<span class="">导入记录</span>
			<em @click="addClick()">导入</em>
		</div>
		<!-- 订单列表 -->
		<el-table :data="list.data" stripe style="width: 100%">
			<el-table-column prop="file" label="文件名">
			</el-table-column>
			<el-table-column prop="import_date" label="时间" align="center">
			</el-table-column>
			<el-table-column prop="goods" :formatter="GoodsFormat" label="商品数量" align="center">
			</el-table-column>
			<el-table-column fixed="right" label="操作" align="center">
				<template slot-scope="scope" v-if="scope.row.order_status != 20">
					<el-button size="mini" plain @click="goDetail(scope.row.id)">查看详情</el-button>
					<el-button size="mini" type="danger" plain @click="del(scope.row.id)">删除</el-button>
				</template>
			</el-table-column>
		</el-table>
<!-- 		<div class="commodity-list">
			<div class="order-title clearfix">
				<ul>
					<li class="details">文件名</li>
					<li class="name">时间</li>
					<li class="price">商品数量</li>
					<li class="operation">操作</li>
				</ul>
			</div>
			<div class="order-product" v-for="(item,index) in list.data" :key="index">
				<ul class="clearfix">
					<li class="details clearfix">
						{{item.file}}
					</li>
					<li class="name">{{item.import_date}}</li>
					<li class="price">{{item.goods_num}}</li>
					<li class="operation" v-if="item.order_status != 20">
						<el-button size="mini" plain @click="goDetail(item.id)">查看详情</el-button>
						<el-button size="mini" type="danger" plain @click="del(item.id)">删除</el-button>
					</li>
				</ul>
			</div>
		</div>
		 -->
		<!-- 分页 -->
		<div class="paging">
			<el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange" background
				:current-page.sync="currentPage" :page-size="15" layout="prev, pager, next, jumper" :total="total">
			</el-pagination>
		</div>
		
		<el-dialog
		  title="导入订单"
		  :visible.sync="dialogVisible"
		  width="30%"
		  >
		  <el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="100px" class="demo-ruleForm">
		  	<el-form-item label="选择导入文件" prop="name">
		  		<el-upload
		  		:action="uploadUrl" 
		  		:auto-upload="true" :limit="1" :file-list="imageList"
		  		ref="upload" :headers="headers"
		  		:on-success="uploadSucc">
		  			<el-button size="small" type="primary">点击上传</el-button>
		  			<div slot="tip" class="el-upload__tip">只能上传Excel文件</div>
		  		</el-upload>
		  	</el-form-item>
		  </el-form>
		</el-dialog>
	</div>
</template>

<script>
	import * as OrderImportApi from '@/api/orderImport'
	export default {
		data() {
			return {
				uploadUrl: process.env.VUE_APP_BASE_API + 'upload/image',
				headers: {
					"Access-Token": localStorage.getItem('token')
				},
				currentPage: 1,
				// 列表数据
				list: [],
				total: 0,
				dialogVisible: false,
				formData: {
					lat: 0.0,
					lng: 0.0,
					fileId: 0
				}
			};
		},
		watch: {
			$route() {
				this.init()
			}
		},
		created() {
			this.init()
			this.getLocation()
		},
		methods: {
			getLocation() {
				if (navigator.geolocation) {
					navigator.geolocation.getCurrentPosition(this.showPosition, this.showError);
				} else {
					console.log("浏览器不支持地理定位。");
				}
			},
			showError(error) {
				switch (error.code) {
					case error.PERMISSION_DENIED:
						console.log("定位失败,用户拒绝请求地理定位");
						break;
					case error.POSITION_UNAVAILABLE:
						console.log("定位失败,位置信息是不可用");
						break;
					case error.TIMEOUT:
						console.log("定位失败,请求获取用户位置超时");
						break;
					case error.UNKNOWN_ERROR:
						console.log("定位失败,定位系统失效");
						break;
				}
			},
			showPosition(position) {
				var lat = position.coords.latitude; //纬度 
				var lng = position.coords.longitude; //经度 
				console.log('纬度:' + lat + ',经度:' + lng);
				this.formData.lat = lat
				this.formData.lng = lng
			},
			init() {
				this.getList()
			},
			getList() {
				let app = this
				OrderImportApi.list()
					.then(result => {
						// 合并新数据
						app.list = result.data.list
						app.total = result.data.list.total
					})
			},
			handleSizeChange(val) {
				console.log(`每页 ${val} 条`);
			},
			handleCurrentChange(val) {
				console.log(`当前页: ${val}`);
				this.currentPage = val
				this.getList()
			},
			addClick() {
				this.title = '新增收货地址'
				this.address = {}
				this.dialogVisible = true
			},
			uploadSucc(response, file, fileList) {
				let app = this
				console.log(fileList)
				app.formData.fileId = fileList[0].response.data.fileInfo.file_id
				console.log(app.formData)
				OrderImportApi.add(app.formData.fileId, app.formData.lat, app.formData.lng)
					.then(result => {
						app.$message.success('导入成功')
						app.dialogVisible = false
						app.$router.push('/import_detail?id=' + result.data.importId);
					})
			},
			goDetail(id) {
				this.$router.push('/import_detail?id=' + id);
			},
			del(id) {
				let app = this
				this.$confirm('确定要删除该行吗?', '提示', {
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					type: 'warning'
				}).then(() => {
					OrderImportApi.del(id)
						.then(result => {
							app.$message.success('删除成功')
							app.init()
						})
				}).catch(() => {});
			},
			GoodsFormat(row){
				return row.goods.length
			}
		}
	};
</script>

<style lang="scss" scoped="scoped">
	@import "../../assets/css/my-import.scss"
</style>
