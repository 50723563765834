import request from '@/utils/request'

// api地址
const api = {
  list: 'orderImport/list',
  add: 'orderImport/add',
  detail: 'orderImport/detail',
  update: 'orderImport/update',
  clear: 'orderImport/clear',
  del: 'orderImport/del'
}

// 导入列表
export function list(param, option) {
  return request({
      url: api.list,
      method: 'get',
  	params: param,
    })
}

export const add = (fileId, lat, lng) => {
	let data = { fileId, lat, lng }
	return request({
		url: api.add,
		method: 'post',
		data
	})
}

export const detail = (importId) => {
	return request({
		url: api.detail,
		method: 'get',
		params: { importId }
	})
}

// 更新购物车商品数量
export const update = (goodsId, goodsSkuId, goodsNum, id) => {
	let data = { goodsId, goodsSkuId, goodsNum, id }
  return request({
      url: api.update,
      method: 'post',
  	  data
    })
}

// 删除购物车中指定记录
export const clear = (cartIds = []) => {
	let data = { cartIds }
  return request({
      url: api.clear,
      method: 'post',
	  data
    })
}

// 删除购物车中指定记录
export const del = (id) => {
	let data = { id }
  return request({
      url: api.del,
      method: 'post',
	  data
    })
}