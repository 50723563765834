<template>
	<div class="my-order">
		<div class="my-title clearfix">
			<span class="">我的订单</span>
		</div>
		<el-form :inline="true" :model="formInline" class="demo-form-inline">
		  <el-form-item label="订单时间:">
				<el-date-picker
					v-model="formInline.time"
					type="daterange"
					range-separator="至"
					start-placeholder="开始日期"
					end-placeholder="结束日期">
				</el-date-picker>
		  </el-form-item>
		  <el-form-item label="关键字:">
		    <el-input v-model="formInline.keyword" placeholder="请输入关键字"></el-input>
		  </el-form-item>
			<el-form-item label="状态:">
				<el-select v-model="formInline.activeName" placeholder="请选择状态">
					<el-option :label="item.name" :value="item.value" v-for="(item, index) in tabs" :key="index"></el-option>
				</el-select>
			</el-form-item>
		  <el-form-item>
		    <el-button type="primary" icon="el-icon-search" @click="onSubmit">搜索</el-button>
		  </el-form-item>
		</el-form>
		<!-- <div class="order-switch">
			<el-tabs v-model="activeName" @tab-click="handleClick" class="tabs-line">
				<el-tab-pane :label="index > 0 && item.num>0 ? item.name+item.num : item.name" :name="item.value"
				v-for="(item, index) in tabs" :key="index">
					<span slot="label" class="name">
						{{item.name}}
						<em class="dot" v-if="index > 0 && item.num>0">{{item.num}}</em>
					</span>
				</el-tab-pane>
			</el-tabs>
			<div class="search">
				<el-input placeholder="请输入商品名称或订单号" v-model="searchInput" class="input-with-select">
					<el-button slot="append" icon="el-icon-search"></el-button>
				</el-input>
			</div>
		</div> -->
		<!-- 订单列表 -->
		<el-table :data="list.data" class="order-table">
			<el-table-column prop="order_no" label="订单号" width="180">
				<template slot-scope="scope">
					<router-link :to="{path: '/myhome/orderdetails', query: { id: scope.row.order_id }}">
						{{scope.row.order_no}}
					</router-link>
				</template>
			</el-table-column>
			<el-table-column prop="create_time" label="订单时间"  align="center" width="180"></el-table-column>
			<el-table-column prop="address.name" label="收货人" align="center"></el-table-column>
			<el-table-column prop="address.phone" label="收货电话" align="center"></el-table-column>
			<el-table-column prop="buyer_remark" label="备注" align="center"></el-table-column>
			<el-table-column prop="total_num" label="数量" align="center" width="60"></el-table-column>
			<el-table-column prop="pay_price" label="金额" align="center">
				<template slot-scope="scope">
					<span class="price">￥<span class="red">{{ scope.row.pay_price }}</span></span>
				</template>
			</el-table-column>
			<el-table-column prop="spec" label="状态" align="center" width="70">
				<template slot-scope="scope">
					<span v-if="false" class="orange">{{ scope.row.state_text }}</span>
          <span class="orange">{{ scope.row.is_wait == 1 ? '待修改' : scope.row.last_state_text }}</span>
				</template>
			</el-table-column>
			<el-table-column label="操作" width="300" align="center">
				<template slot-scope="scope">
					<el-button class="button-warning-mini" size="mini" plain v-if="scope.row.state===4" @click="deleteItem(scope.$index)">删除</el-button>
					<a target="_blank" :href="reportUrl+scope.row.order_id">
						<el-button class="button-warning-mini">打印&导出</el-button>
					</a>
					<span v-if="scope.row.order_status != 20">
<!--						<el-button class="button-warning-mini" v-if="scope.row.pay_status.value == 10" @click="onCancel(scope.row.order_id)" size="mini" plain>取消订单</el-button>-->
						<span v-if="scope.row.order_status != OrderStatusEnum.APPLY_CANCEL.value" @click="onCancel(scope.row.order_id)">
							<el-button class="button-warning-mini" size="mini" plain v-if="scope.row.pay_status == PayStatusEnum.SUCCESS.value && scope.row.delivery_status == DeliveryStatusEnum.NOT_DELIVERED.value">
								取消订单</el-button>
						</span>
						<span v-else>取消申请中</span>
						<el-button v-if="scope.row.pay_status == 10" @click="onPay(scope.row.order_id)" class="button-primary-mini" size="mini" type="primary" plain>立即付款</el-button>
						<!-- <span v-if="scope.row.state===2" @click="reminderClick">
							<el-button class="button-primary-mini" size="mini" plain>催单</el-button>
						</span> -->
						<el-button class="button-success-mini" size="mini" type="success" plain
						v-if="scope.row.delivery_status == DeliveryStatusEnum.DELIVERED.value && scope.row.receipt_status == ReceiptStatusEnum.NOT_RECEIVED.value" @click="onReceipt(scope.row.order_id)">确认收货</el-button>
						<!-- <span v-if="scope.row.state===4" @click="retreatClick">
							<el-button class="button-warning-mini" size="mini" plain>退换退款</el-button>
						</span> -->
						<span v-if="scope.row.order_status == OrderStatusEnum.COMPLETED.value && scope.row.is_comment == 0">
							<!-- <el-button size="mini" plain @click="handleTargetComment(scope.row.order_id)">评价</el-button> -->
<!--							<el-button class="button-primary-mini" v-if="scope.row.is_import == 0" size="mini" plain @click="handleImport(scope.row)">导入进销存</el-button>-->
						</span>
					</span>
				</template>
			</el-table-column>
		</el-table>
		<!-- 分页 -->
		<div class="paging">
			<el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange" background
				:current-page.sync="currentPage" :page-size="15" layout="prev, pager, next, jumper" :total="total">
			</el-pagination>
		</div>

		<el-dialog
		  title="选择支付方式"
		  :visible.sync="dialogVisible"
		  width="30%"
		  >
		  <div style="text-align: center;">
			  <el-button type="primary" plain icon="el-icon-coin" @click="onSelectPayType(PayTypeEnum.BALANCE.value)">{{ PayTypeEnum.BALANCE.name }}</el-button>
              <el-button type="primary" plain icon="el-icon-money" @click="onSelectPayType(PayTypeEnum.WECHAT.value)">{{ PayTypeEnum.WECHAT.name }}</el-button>
		  </div>
		</el-dialog>
	</div>
</template>

<script>
	import {
	  DeliveryStatusEnum,
	  DeliveryTypeEnum,
	  OrderStatusEnum,
	  PayStatusEnum,
	  PayTypeEnum,
	  ReceiptStatusEnum
	} from '@/common/enum/order'
	import * as OrderApi from '@/api/order'
	import * as PsiApi from '@/api/psi'
	export default {
		data() {
			return {
				formInline: {
					time: '',
					keyword: '',
					activeName:'all'
				},
				reportUrl: process.env.VUE_APP_REPORT_URL + '588863542348484608?order_id=',
				// 枚举类
				DeliveryStatusEnum,
				DeliveryTypeEnum,
				OrderStatusEnum,
				PayStatusEnum,
				PayTypeEnum,
				ReceiptStatusEnum,

				currentPage: 1,
				activeName: 'all',
				// 订单列表数据
				list: [],
				total: 0,
				searchInput: '',
				dialogVisible: false,
				payOrderId: 0,
				tabs: [{
				  name: `全部`,
				  value: 'all',
				  num: 0,
				}, {
				  name: `待支付`,
				  value: 'payment',
				  num: 0,
				}, {
				  name: `待发货`,
				  value: 'delivery',
				  num: 0,
				}, {
				  name: `待收货`,
				  value: 'received',
				  num: 0,
				}, {
				  name: `待评价`,
				  value: 'comment',
				  num: 0,
				}]
			};
		},
		watch:{
			$route(){
				this.init()
			}
		},
		created() {
			this.init()
		},
		methods: {
      // 搜索
      onSubmit() {
        this.currentPage = 1
        this.getList()
      },
      init() {
        if (this.$route.query.dataType) {
          this.activeName = this.$route.query.dataType
        }
        this.getList()
        this.getListNum()
      },
      getListNum() {
        let app = this
        app.tabs.forEach((item, index) => {
          if (index > 0) {
            OrderApi.list({dataType: item.value, page: 1}, {load: false})
                .then(result => {
                  item.num = result.data.list.total
                })
          }
        })
      },
      getList() {
        let app = this
        OrderApi.list({
          dataType: app.formInline.activeName,
          searchValue: app.formInline.keyword,
          betweenTime: app.formInline.time,
          page: app.currentPage
        }, {load: false})
            .then(result => {
              // 合并新数据
              app.list = app.initList(result.data.list)
              app.total = result.data.list.total
              let tabIndex = app.tabs.findIndex(item => item.value == app.activeName)
              app.tabs[tabIndex].num = app.total
            })
      },
      // 初始化订单列表数据
      initList(newList) {
        newList.data.forEach(item => {
          item.total_num = 0
          item.goods.forEach(goods => {
            item.total_num += goods.total_num
          })
        })
        return newList
      },
      handleSizeChange(val) {
        console.log(`每页 ${val} 条`);
      },
      handleCurrentChange(val) {
        console.log(`当前页: ${val}`);
        this.currentPage = val
        this.getList()
      },
      // 取消订单
      onCancel(orderId) {
        const app = this
        app.$confirm('确认要取消该订单吗?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          OrderApi.cancel(orderId)
              .then(result => {
                // 显示成功信息
                app.$message.success(result.message)
                // 刷新订单列表
                app.getList()
              })
        })
      },

      // 确认收货
      onReceipt(orderId) {
        const app = this
        app.$confirm('确认收到商品了吗?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          OrderApi.receipt(orderId)
              .then(result => {
                // 显示成功信息
                app.$message.success(result.message)
                // 刷新订单列表
                app.getList()
              })
        })
      },

      // 点击去支付
      onPay(orderId) {
        // 记录订单id
        this.payOrderId = orderId
        // 显示支付方式弹窗
        this.dialogVisible = true
      },

      // 选择支付方式
      onSelectPayType(payType) {
        const app = this
        // 隐藏支付方式弹窗
        app.dialogVisible = false
        if (payType == PayTypeEnum.WECHAT.value) {
          app.$router.push('/settlement/wxpay?id=' + app.payOrderId)
          return
        }
        // 发起支付请求
        OrderApi.pay(app.payOrderId, payType)
            .then(result => app.onSubmitCallback(result))
      },

      // 订单提交成功后回调
      onSubmitCallback(result) {
        const app = this
        // 余额支付
        if (result.data.pay_type == PayTypeEnum.BALANCE.value) {
          app.$message.success('支付成功')
          setTimeout(() => {
            app.getList()()
          }, 1500)
        }
        //诺诺支付
        if (result.data.pay_type == PayTypeEnum.NUONUO.value) {
          let payment = JSON.parse(result.data.payment)
          if (payment.code != "JH200") {
            this.$message.error(payment.describe)
          } else {
            this.$router.push('/settlement/nuonuo?id=' + result.data.order_id + '&payment=' + encodeURIComponent(payment.result.payUtl))
          }
        }
      },
      // 评价
      evaluateClick() {
        this.$router.push('/myhome/evaluate');
      },
      // 跳转到订单评价页
      handleTargetComment(orderId) {
        this.$router.push('/myhome/evaluate?id=' + orderId)
      },
      handleClick(tab, event) {
        this.activeName = tab.name
        this.getList()
      },
      handleImport(item) {
        PsiApi.order_import({order_id: item.order_id})
            .then(result => {
              item.is_import = 1
              this.$message.success('导入成功')
            })
      },
    }
	};
</script>

<style lang="scss" scoped="scoped">
	@import "../../assets/css/my-order.scss"
</style>
